import React, { Component } from "react"
import { Link, graphql } from "gatsby"
import Masonry from "react-masonry-component"
import LazyLoad from "react-lazyload"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { Box, Text, Divider } from "@chakra-ui/core"

const masonryOptions = {
  transitionDuration: 2,
  itemSelector: ".grid-item",
  columnWidth: 1,
  gutter: 40,
  visibleStyle: { transform: "translateY(0)", opacity: 1 },
  hiddenStyle: { transform: "translateY(100px)", opacity: 0 },
}
const imagesLoadedOptions = {}

const Placeholder = () => <Box maxW="md" p="6" className="grid-item" />

const Item = ({ image, publication, title, url }) => {
  return (
    <LazyLoad placeholder={<Placeholder />} height={200}>
      <Box
        maxW="md"
        p="6"
        className="grid-item"
        borderWidth="1px"
        rounded="lg"
        mb="5"
      >
        <img
          style={{ minHeight: "180px" }}
          src={image}
          alt={`${publication}, ${title}`}
        />
        <Divider />
        {url ? (
          <Text fontSize="sm" pt="2">
            <a
              href={url}
              target="_blank"
              style={{ textDecoration: "underline" }}
            >
              {publication}, {title}
            </a>
          </Text>
        ) : (
          <Text fontSize="sm" pt="2">
            {publication}, {title}
          </Text>
        )}
      </Box>
    </LazyLoad>
  )
}

class BlogIndex extends Component {
  constructor() {
    super()
    this.state = { loaded: false }
    this.handleImagesLoaded = this.handleImagesLoaded.bind(this)
  }
  handleImagesLoaded() {
    console.log("image loaded")
    this.setState({ loaded: true })
  }
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allMarkdownRemark.edges

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="All posts" />
        <Masonry
          className={"centered"} // default ''
          // elementType={"ul"} // default 'div'
          options={masonryOptions} // default {}
          disableImagesLoaded={false} // default false
          updateOnEachImageLoad={false} // default false and works only if disableImagesLoaded is false
          onImagesLoaded={this.handleImagesLoaded}
          imagesLoadedOptions={imagesLoadedOptions} // default {}
        >
          {posts.map(({ node }) => {
            const title = node.frontmatter.title || node.fields.slug
            const { publication, url } = node.frontmatter
            return (
              <Item
                className="grid-item"
                key={node.fields.slug}
                image={node.frontmatter.image}
                publication={publication}
                title={title}
                url={url}
              />
            )
          })}
        </Masonry>
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            publication
            description
            image
            url
          }
        }
      }
    }
  }
`
